.host-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: auto;
  border: 1px solid black;
}

@media (prefers-color-scheme: light) {
  .host-container {
    background-color: white;
    color: black;
  }
}

@media (prefers-color-scheme: dark) {
  .host-container {
    background-color: grey;
    color: white;
  }
}

.host {
  background-color: white;
}

.project-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  margin: auto;
  text-align: center;
}
